import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import routesModel from './routes'
import global from "../store/global";
Vue.use(VueRouter);

const routes = [
  ...routesModel,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  global.dispatch("reset");

  if (to.matched.some((r) => r.meta.requireAuth)) {
    if (store.state.token == undefined || store.state.token == null || store.state.user == null) {
      next("/login");
    }
    let currentUserType = store.state.user.userType.id;
    // Check if user type is 0 or 2
    if (currentUserType != global.state.roleType.admin) {
      // Check if user has permission to access the page based on their roles
      if (store.state.user.roles.length == 0 || !store.state.user.roles.some(role => to.meta.auth.includes(role.key))) {
        next("/login");
        return;
      }
    }
  }
  next();
});


export default router;