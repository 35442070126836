<template>
<v-app class="app" :style="{background: $vuetify.theme.themes[theme].background}">
    <layout/>
    <!-- <imageLayout v-if="$route.meta.hideNavigation" /> -->
    <alertDialog />
    <confirmDialog />
</v-app>
</template>

<script>
// import {UserIcon} from 'vue-feather-icons';
export default {
    components: {
        layout: () => import('@/components/layout/Layout.vue'),
        // imageLayout: () => import('@/components/layout/imageLayout'),
        alertDialog: () => import('@/components/alertComponents/alertDialog'),
        confirmDialog: () => import('@/components/alertComponents/confirmDialog')
    },
    computed: {
        theme() {
            return (this.$vuetify.theme.dark) ? 'dark' : 'light'
        }
    }
}
</script>

