import axios from 'axios'
import service from '../service'

export default axios.create({
    baseURL: service.url+'/api/',
    headers: {
      Authorization: {
        toString () {
          return `Bearer ${localStorage.getItem('token')}`
        }
      },
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
    }
})
