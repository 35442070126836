<template>
  <v-col :cols="cols" :md="md">
    <h3 v-if="fieldTitle" :class="disabled ? 'grey--text' : 'black--text'">
      <span :class="$vuetify.theme.dark ? 'white--text' : 'black--text'">{{ title }}</span>
      <span v-if="required" class="required"> * </span>
    </h3>
    <v-autocomplete
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      :label="label"
      :outlined="!disabled"
      filled
      :disabled="disabled"
      :placeholder="title"
      v-model="computedValue"
      hide-details="auto"
      :dense="dense"
      no-data-text="لا توجد بيانات"
      :loading="loading"
      @change="handleChange"
    />
  </v-col>
</template>

<script>
export default {
  props: ['label', 'cols', 'md', 'placeholder', 'title', 'required', 'value', 'items', 'itemText', 'itemValue', 'disabled', 'dense', 'fieldTitle', 'loading', 'returnObject'], 
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  },
  methods: {
    handleChange(newValue) {
      if (this.returnObject) {
        // Emit the entire object
        const selectedItem = this.items.find(item => item[this.itemValue] === newValue);
        this.$emit('input', selectedItem);
      }
    }
  }
};
</script>
