import Vue from "vue";
import Vuex from "vuex";
import axios from "../axios";
import service from "../service/index";
import validations from "@/utils/validations";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        ...validations,
        loading: false,
        uploading: false,
        path: "",
        justNumbers: "return (event.charCode >= 48 && event.charCode <= 57)",

        filter: {
            pageIndex: 1,
            pageSize: 20,
            search: "",
            userId: "",
            startDate: "",
            endDate: "",
        },

        selectForm: "",
        provinceId: "",
        genderId: "",
        barcode: "",
        printDetails: null,

        length: 0,
        count: 0,

        attachments: [
            {
                filePath: "",
                fileType: "",
            },
        ],

        errors: [],
        errorMessage: "",

        roleType: {
            admin: 1,
            employee: 2,
            merchant: 3,
            warehouse: 4,
            delivery: 5,
            receiving: 6,
        },

        genders: [
            {
                id: true,
                name: "ذكر",
            },
            {
                id: false,
                name: "أنثى",
            },
        ],

        userTypes: [
            {
                id: 3,
                name: "تاجر",
            },
            {
                id: 5,
                name: "مندوب توصيل",
            },
            {
                id: 6,
                name: "مندوب استلام",
            },
        ],

        transactionTypes: [
            {
                id: 0,
                name: "استحقاق",
            },
            {
                id: 1,
                name: "ذمة",
            },
        ],

        paymentTypes: [
            {
                id: 0,
                name: "معلق",
            },
            {
                id: 1,
                name: "مسدد - مستلم (جزئي)",
            },
            {
                id: 2,
                name: "مسدد - مستلم (كامل)",
            },
        ],

        governorates: [
            {
              "name": "بغداد",
              "id": 1,
            },
            {
              "name": "بابل",
              "id": 2,
            },
            {
              "name": "الانبار",
              "id": 3,
            },
            {
              "name": "البصرة",
              "id": 4,
            },
            {
              "name": "ذي قار",
              "id": 5,
            },
            {
              "name": "ديالى",
              "id": 6,
            },
            {
              "name": "دهوك",
              "id": 7,
            },
            {
              "name": "اربيل",
              "id": 8,
            },
            {
              "name": "كربلاء",
              "id": 9,
            },
            {
              "name": "كركوك",
              "id": 10,
            },
            {
              "name": "ميسان",
              "id": 11,
            },
            {
              "name": "المثنى",
              "id": 12,
            },
            {
              "name": "النجف",
              "id": 13,
            },
            {
              "name": "نينوى",
              "id": 14,
            },
            {
              "name": "القادسية",
              "id": 15,
            },
            {
              "name": "صلاح الدين",
              "id": 16,
            },
            {
              "name": "السليمانية",
              "id": 17,
            },
            {
              "name": "واسط",
              "id": 18,
            }
          ],

        authGroupsRoles: [],
    },

    actions: {
        reset() {
            (this.state.attachments = [
                {
                    filePath: "",
                    fileType: "",
                    description: "",
                },
            ]),
                (this.state.provinceId = ""),
                (this.state.filter.pageIndex = 1),
                (this.state.filter.pageSize = 50),
                (this.state.length = 0),
                (this.state.count = 0),
                (this.state.filter.search = "");
        },

        async handleFileUpload(context, { file, formDataName, endPoint }) {
            this.state.uploading = true;
            try {
                let formData = new FormData();
                // formData.append('files', file);
                formData.append(formDataName, file);
                let res = await axios.post(endPoint, formData);
                formDataName == "files"
                    ? (this.state.path = res.data.fileList[0].path)
                    : "";
                service.success("تم رفع الملف بنجاح");
                // service.refresh('refresh')
            } catch (err) {
                console.log(err);
                service.faild(err.response.data.message);
            } finally {
                this.state.uploading = false;
            }
        },


        async getAuthGroups() {
            this.state.loading = true;
            try {
                const res = await axios.get(`AuthGroups`);
                this.state.authGroups = res.data.result;
            } catch (err) {
                console.log(err);
                service.faild(err.response.data.message);
            } finally {
                this.state.loading = false;
            }
        },

        async getUsers() {
            this.state.loading = true;
            try {
                const res = await axios.get(
                    `Users?PageIndex=${this.state.filter.pageIndex}&PageSize=${this.state.filter.pageSize}&Search=${this.state.filter.search}&IsDelete=false`
                );
                this.state.users = res.data.result.data;
                this.state.count = res.data.result.count;
                this.state.length =
                    res.data.result.count == undefined
                        ? 0
                        : Math.ceil(
                              res.data.result.count / this.state.filter.pageSize
                          );
            } catch (err) {
                console.log(err);
                service.faild(err.response.data.message);
            } finally {
                this.state.loading = false;
            }
        },
    },
    modules: {},
});
