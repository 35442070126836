import global from "../store/global";
export default [
  {
    path: "/pageNotFound",
    name: "pageNotFound",
    component: () => import("../views/404.vue"),
    meta: {
      hideNavigation: true,
    },
  },
  {
    path: "/home",
    name: "الرئيسية",
    component: () => import("../views/home/home.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/employee",
    name: "الموظفين",
    component: () => import("../views/users/employees.vue"),
    meta: {
        single: "موظف",
        endPoint: "Users",
        breadcrumbs: [{ text: "موظف", disabled: true }],
        requireAuth: false,
        auth: ['user'],
        type: global.state.roleType.employee
    },
  },
  {
    path: "/merchant",
    name: "التجار",
    component: () => import("../views/users/merchant.vue"),
    meta: {
        single: "تاجر",
        endPoint: "Users",
        breadcrumbs: [{ text: "تاجر", disabled: true }],
        requireAuth: false,
        auth: ['user'],
        type: global.state.roleType.merchant
    },
  },
  {
    path: "/warehouse_manager",
    name: "امناء المخازن",
    component: () => import("../views/users/warehouse_manager.vue"),
    meta: {
        single: "امين مخزن",
        endPoint: "Users",
        breadcrumbs: [{ text: "امين مخزن", disabled: true }],
        requireAuth: false,
        auth: ['user'],
        type: global.state.roleType.warehouse
    },
  },
  {
    path: "/delivery_delegater",
    name: "مندوبين التوصيل",
    component: () => import("../views/users/delivery_delegaters.vue"),
    meta: {
        single: "مندوب توصيل",
        endPoint: "Users",
        breadcrumbs: [{ text: "مندوب توصيل", disabled: true }],
        requireAuth: false,
        auth: ['user'],
        type: global.state.roleType.delivery
    },
  },
  {
    path: "/receiving_delegater",
    name: "مندوبين الاستلام",
    component: () => import("../views/users/receiving_delegaters.vue"),
    meta: {
        single: "مندوب استلام",
        endPoint: "Users",
        breadcrumbs: [{ text: "مندوب استلام", disabled: true }],
        requireAuth: false,
        auth: ['user'],
        type: global.state.roleType.receiving
    },
  },
  {
    path: "/warehouse",
    name: "المخازن",
    component: () => import("../views/warehouses/warehouses.vue"),
    meta: {
      single: "مخزن",
      requireAuth: false,
      type: 1,
      auth: ['warehouse'],
    },
  },
  {
    path: "/warehouseDelegater",
    name: "المخازن",
    component: () => import("../views/warehouses/warehouses.vue"),
    meta: {
      single: "مخزن",
      requireAuth: false,
      type: 2,
      auth: ['warehouse'],
    },
  },
  {
    path: "/office",
    name: "المكاتب",
    component: () => import("../views/offices/offices.vue"),
    meta: {
      single: "مكتب",
      requireAuth: false,
      auth: ['office'],
    },
  },
  {
    path: "/batches",
    name: "الحزم",
    component: () => import("../views/batches/index.vue"),
    meta: {
      single: "حزمة",
      requireAuth: false,
      endPoint: "Batch",
      auth: ['batches'],
    },
  },
  {
    path: "/order",
    name: "الشحنات",
    component: () => import("../views/orders/orders.vue"),
    meta: {
      single: "شحنة",
      requireAuth: false,
      endPoint: "order",
      auth: ['order'],
    },
  },
  {
    path: "/order/:id",
    name: "تفاصيل الشحنة",
    component: () => import("../views/orders/orderDetails.vue"),
    meta: {
      single: "تفاصيل الشحنة",
      requireAuth: false,
      endPoint: "order",
      auth: ['order'],
    },
  },
  {
    path: "/order/print/:id",
    name: "تفاصيل الشحنة",
    component: () => import("../views/orders/printOrder.vue"),
    meta: {
      single: "تفاصيل الشحنة",
      requireAuth: false,
      endPoint: "order",
      auth: ['order'],
      hideNavigation: true,
    },
  },
  {
    path: "/merchantbills",
    name: "وصولات التجار",
    component: () => import("../views/merchantbills/merchantbills.vue"),
    meta: {
      single: "حجز وصولات",
      requireAuth: false,
      endPoint: "Merchantbills",
      auth: ['merchantbills'],
    },
  },
  {
    path: "/merchantbills/print/:range",
    name: "طباعة دفتر الوصولات",
    component: () => import("../views/merchantbills/printbills.vue"),
    meta: {
      single: "دفتر الوصولات",
      requireAuth: false,
      endPoint: "Merchantbills",
      auth: ['merchantbills'],
      hideNavigation: true,
    },
  },
  {
    path: "/area",
    name: "المناطق",
    component: () => import("../views/area/area.vue"),
    meta: {
      single: "منطقة",
      requireAuth: false,
      endPoint: "Area",
      auth: ['area'],
    },
  },
  {
    path: "/todo",
    name: "المناطق",
    component: () => import("../views/todo/todo.vue"),
    meta: {
      single: "طلب سريع",
      requireAuth: false,
      endPoint: "todo",
      auth: ['todo'],
    },
  },
  {
    path: "/paymentTransaction",
    name: "سجل الحسابات",
    component: () => import("../views/paymentTransaction/paymentTransaction.vue"),
    meta: {
      single: "",
      requireAuth: false,
      endPoint: "PaymentTransaction",
    },
  },
  {
    path: "/transactionCategory",
    name: "اصناف القيود المحاسبية",
    component: () => import("../views/transactionCategory/transactionCategory.vue"),
    meta: {
      single: "صنف",
      requireAuth: false,
      endPoint: "transactionCategory",
    },
  },
  {
    path: "/transaction",
    name: "سجل القيود الحسابية",
    component: () => import("../views/transaction/transaction.vue"),
    meta: {
      single: "صنف",
      requireAuth: false,
      endPoint: "transaction",
    },
  },
  {
    path: "/orderHistory",
    name: "سجل الحركات",
    component: () => import("../views/orderHistory/orderHistory.vue"),
    meta: {
      single: "",
      requireAuth: false,
      endPoint: "orderHistory",
    },
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../views/test.vue"),
    meta: {
      single: "",
      requireAuth: false,
      endPoint: "orderHistory",
      hideNavigation: true
    },
  },
  {
    path: "/authGroup",
    name: "الصلاحيات",
    component: () => import("../views/authGroup/authGroup.vue"),
    meta: {
      single: "",
      requireAuth: false,
      endPoint: "authGroup",
    },
  },
  {
    path: "/merchantsRecivedAmountReport",
    name: "تقرير كشف الواصل للتاجر",
    component: () => import("../views/reports/merchantsRecivedAmountReport/merchantsRecivedAmountReport.vue"),
    meta: {
      single: "تقرير كشف الواصل للتاجر",
      requireAuth: false,
      endPoint: "merchantsRecivedAmountReport",
    },
  },
  {
    path: "/balanceWithdrawalRequest",
    name: "طلبات سحب الرصيد",
    component: () => import("../views/balanceWithdrawalRequest/page.vue"),
    meta: {
      single: "طلبات سحب الرصيد",
      requireAuth: false,
      endPoint: "balanceWithdrawalRequest",
    },
  },
  
  {
    path: "/login",
    name: "تسجيل الدخول",
    component: require("../views/auth/login.vue").default,
    meta: {
      hideNavigation: true,
    },
  },
  { 
    path: "*",
    meta: {
      requireAuth: true,
    },
    redirect: "/login",
  },
  
]
