const axios = require('axios');
const EventEmitter = require('events');
const eventBus = new EventEmitter();
const numeral = require('numeral');
// import store from "../store/index";
// import global from "../store/global";
// const store = require('../store/index');
// const global = require('../store/global');
// const url = process.env.NODE_ENV == 'production' ? 'http://108.61.190.15:9977' : 'https://localhost:44303' ;
// const url = process.env.NODE_ENV == 'production' ? 'http://192.168.11.11:9977' : 'https://localhost:44303' ;
const config = require('../../public/config.json')
const url = process.env.NODE_ENV == process.env.NODE_ENV == 'production' ? config.BASE_URL_PROD : config.BASE_URL_DEV  ; 
// const url = process.env.NODE_ENV == 'production' ? 'http://192.168.11.249:9977' : 'https://localhost:44303' ;

    const warningText = 'هل أنت متأكد من هذا الإجراء؟';
    const successText = 'تم الحذف بنجاح';

    function deleteItem(id, api, code, myWarningText, mySuccessText){
        
        eventBus.emit('confirm', {
            id: id,
            text:  myWarningText == undefined ? warningText : myWarningText,
            code: code
        });

        eventBus.once(code ,(data1)=> {
            axios({
                url: url+'/api/'+ api+'/'+id,
                method: 'delete',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                  },
                // data: {id: id}

            }).then( res => {
            eventBus.emit('send-data', {
                    type:  'done',
                    text: mySuccessText == undefined ? successText : mySuccessText,
                });
            }).catch(err => {
            eventBus.emit('send-data', {
                type:  'error',
                text: 'فشلت العملية',
            });
        });
    });}

    const successText2 = 'تمت العملية بنجاح'
    const faildText = 'حدث خطأ'

    function faild(myText){
        eventBus.emit('send-data', {
            type:  'error',
            text: myText == undefined ? faildText : myText,
        });
    }

    function success(myText){
        eventBus.emit('send-data', {
            type:  'done',
            text: myText == undefined ? successText2 : myText,
        });
    }

    function formatCurrency(x){
        return numeral(x).format("0,0")
    }

    function refresh(key){
        // console.log(key)
        eventBus.emit(key)
    }

    // function checkPermissin(operation){
    //     if(store.state.user.roles.find(x => x == `${window.location.pathname.slice(1)}-${operation}`)|| store.state.user.userData.type == global.state.roleType.admin){
    //         return true;
    //     }else{
    //         return false;
    //     }
    // }
    function formatRecord(items) { 
        let arr = items
        for (let i = 0; i < arr.length; i++) { //loop for all objects
            let currentObject = Object.entries(arr[i]) // convert the current object to list
            let newObject = currentObject // new variable to store the new object
            for (let j = 0; j < currentObject.length; j++) { // loop for the current objectb after convert it to list
                if (Number.isInteger(currentObject[j][1])) { // check if the current value is number to format it
                    newObject[j][1] = this.formatCurrency(currentObject[j][1]);
                } 
              //   else if (!isNaN(Date.parse(new Date(currentObject[j][1])))) { // check if the value is date type
              //       // console.log(currentObject[j][1])
              //       newObject[j][1] = currentObject[j][1].substring(0, 10)
              //   } 
                else {
                    newObject[j][1] = currentObject[j][1];
                }
            }
            arr[i] = Object.fromEntries(newObject) // convert the list to object and set it to the original place
        }
        // console.log(arr)
        return arr;
      }


module.exports = {
    url: url,
    deleteItem: deleteItem,
    eventBus: eventBus,
    faild: faild,
    success: success,
    formatCurrency: formatCurrency,
    refresh: refresh,
    formatRecord: formatRecord
    // checkPermissin:checkPermissin
}
