<template>
  <v-col :cols="cols" :md="md">
    <h3 v-if="fieldTitle" :class="disabled ? 'grey--text' : 'black--text'">
      <span :class="$vuetify.theme.dark ? 'white--text' : 'black--text'">{{ title }}</span>
      <span v-if="required" class="required"> * </span>
    </h3>
    <v-text-field
      :label="label"
      :outlined="!disabled"
      filled
      :placeholder="title"
      :value="formattedValue"
      @input="updateValue"
      :suffix="suffix"
      :disabled="disabled"
      hide-details="auto"
      :dense="dense"
    />
  </v-col>
</template>

<script>
export default {
  props: ['label', 'cols', 'md', 'placeholder', 'title', 'required', 'value', 'disabled', 'dense', 'suffix', 'fieldTitle'],
  data() {
    return {
      inputValue: this.value, // Store the unformatted value
    };
  },
  computed: {
    // Format the value for display
    formattedValue() {
      // You should replace 'formatCurrency' with the actual method from your service
      return this.$genericService.formatCurrency(this.inputValue);
    },
  },
  methods: {
    // Update the value when input changes
    updateValue(formattedValue) {
      // Remove formatting before storing
      const unformattedValue = formattedValue.replace(/,/g, '');
      this.inputValue = unformattedValue;
      // Emit the unformatted value to the parent
      this.$emit('input', unformattedValue);
    },
    generateUniqueKey() {
      // Create a unique key based on relevant properties
      return this.item.merchantId + this.item.deliveryGovernorateId;
    },
  },
};
</script>
